import {gql} from '@apollo/client'

export const GET_ORDER = gql`
  query GetOrder($id: UUID!) {
    order(id: $id) {
      id
      name
      orderNumber
      orderType
      orderDeliveryMethod
      fulfillmentStatus
      financialStatus
      totalPrice
      subtotalPrice
      createdAt
      shippingPrice
      totalDiscounts
      wineClubDiscounts
      totalTax
      taxId
      customerId
      shippingRateId
      discounts
      note
      notes
      fulfillmentLocationId
      isPosEdit
      isAdminEnabledPosCancel
      salesTax
      crv
      isCrvEnabled
      invoiceNumber
      isDisableEdit
      status
      isAutoDiscountRemoved
      isTaxRemoved
      customer {
        id
        firstName
        lastName
        email
        phone
        shippingPreferenceCompany
        hasWineClub
      }
      transactions {
        nodes {
          amount
          paymentType
          createdAt
          withoutTipAmount
          tipAmount
          metaData
        }
      }
      shippingAddress {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        city
        state {
          code
          name
        }
        country {
          code
        }
        zipCode
      }
      paymentMethod {
        id
        card {
          brand
          last4
          expYear
          expMonth
        }
        isPrimary
        address {
          firstName
          lastName
          addressLine2
          addressLine1
          city
          country {
            code
          }
          state {
            code
            name
          }
          zipCode
        }
      }
      billingAddress {
        id
        firstName
        lastName
        addressLine1
        addressLine2
        city
        state {
          code
          name
        }
        country {
          code
        }
        zipCode
      }
      migratedOrderItems {
        nodes {
          amount
          sku
          quantity
        }
      }
      orderItems {
        nodes {
          id
          fulfillableQuantity
          fulfilledQuantity
          refundedQuantity
          price
          quantity
          variantId
          originalPrice
          variant {
            available
            id
            name
            sku
            discountPrice
            pickupAvailableForAdminPortal
            shipAvailableForAdminPortal
            weight {
              value
              units
            }
            product {
              id
              name
              imageSrc
            }
          }
        }
      }
      shippingRate {
        title
      }
      tipAmount
      refunds(orderBy: CREATED_AT_DESC) {
        nodes {
          createdAt
          refundItems {
            nodes {
              quantity
              price
              variant {
                name
                product {
                  id
                  name
                  imageSrc
                }
              }
            }
          }
        }
      }
      orderHistories(orderBy: CREATED_AT_DESC, filter: {activity: {isNull: false}}) {
        nodes {
          createdAt
          title: activity
          summary
          orderItems {
            nodes {
              originalQuantity
              orderItemId
              quantity
              price
              variant {
                name
                product {
                  id
                  name
                  imageSrc
                }
              }
            }
          }
          refundItems {
            nodes {
              quantity
              price
              variant {
                name
                product {
                  id
                  name
                  imageSrc
                }
              }
            }
          }
        }
      }
      checkout {
        shippingRate {
          price
          serviceType
          title
        }
      }
      fulfillments {
        nodes {
          id
          isPickup
          locationId
          fulfillmentShippingLabels {
            nodes {
              url
              graphicImage
              encodedLabel
              docType
            }
          }
          trackingUrl
          trackingCompanyName
          tracking {
            trackingNumber
            companyName
          }
          fulfillmentItems {
            nodes {
              quantity
              id
              price
              orderedQuantity
              refundedQuantity
              orderItemId
              variant {
                sku
                name
                product {
                  id
                  name
                  imageSrc
                }
              }
            }
          }
        }
      }
      externalOrderTrackings(filter: {status: {notEqualTo: QUEUED}}) {
        nodes {
          createdAt
          appType
          status
          message
        }
      }
    }
  }
`

export const ORDER_CANCEL = gql`
  mutation OrderCancel($id: UUID!, $reason: String!) {
    orderCancel(input: {orderId: $id, reason: $reason}) {
      status
      errors
      statusCode
    }
  }
`

export const GET_ORDER_ITEMS_BY_ORDER_ID = gql`
  query GetOrder($id: UUID!) {
    order(id: $id) {
      orderItems {
        nodes {
          id
          price
          quantity
          variant {
            name
            product {
              name
            }
          }
        }
      }
    }
  }
`

export const UPDATE_FULFILLMENT_LOCATION = gql`
  mutation UpdateFulfillment($locationId: UUID, $id: UUID!) {
    updateFulfillment(input: {patch: {locationId: $locationId}, id: $id}) {
      fulfillment {
        id
      }
    }
  }
`

export const GET_FULFILLMENT_CURRENT_LOCATION = gql`
  query GetCurrentLocation($id: UUID!) {
    fulfillment(id: $id) {
      locationId
    }
  }
`

export const CREATE_ORDER_NOTES = gql`
  mutation UpdateOrderNotesById($notes: String, $orderId: UUID) {
    updateOrderNotesById(input: {pNotes: $notes, pOrderId: $orderId}) {
      json
    }
  }
`
