export const compliantStatusTypes = {
  COMPLIANT: 'Compliant',
  NOT_COMPLIANT: 'NotCompliant',
  NOT_COMPLIANT_WITH_REASON: 'NotCompliantWithReason',
}

export const deliveryTypes = {
  pickup: 'PICKUP',
  ship: 'SHIP',
}

export const deliveryMethodTypes = [
  {
    name: 'Shipping',
    value: 'SHIP',
  },
  {
    name: 'Pickup',
    value: 'PICKUP',
  },
]

export const paymentStatusTypes = {
  paid: 'PAID',
  pending: 'PENDING',
  failed: 'PAYMENT_FAILED',
  partialRefund: 'PARTIALLY_REFUNDED',
}

export const fulfillmentStatusTypes = {
  fulfilled: 'FULFILLED',
  unfulfilled: 'UNFULFILLED',
  restocked: 'RESTOCKED',
  readyToFulfill: 'READY_TO_FULFILL',
}

export const taskStatusTypes = {
  success: 'SUCCESS',
  queued: 'QUEUED',
  error: 'ERROR',
}

export const reportTypes = {
  sales: 'sales',
  customer: 'customers',
  wineclubMembers: 'wine_club_members',
  winetastingBooking: 'wine_tasting_booking',
  product: 'products',
  inHouseFulfillment: 'in_house_fulfillment',
  salesByClubLevelAll: 'sales_by_club_level_all_report',
  customerLTVReport: 'customer_by_ltv_report',
  salesByClubLevel: 'sales_by_club_level_report',
  salesByState: 'sales_by_state_report',
  salesByProductType: 'sales_by_product_type_report',
  salesByDiscount: 'sales_by_discount_report',
  masterInventory: 'master_inventory_report',
  salesByEvent: 'sales_by_event_report',
  salesByCustomerType: 'sales_by_customer_type_report',
  salesByProduct: 'sales_by_product_report',
  salesByPaymentType: 'sales_by_payment_type_report',
  salesByOrder: 'sales_by_order_type_report',
  wineClubPackageMembers: 'wine_club_package_members',
  inventories: 'inventories',
  salesOrder: 'sales_by_order',
  salesByTax: 'sales_by_tax_report',
  posSalesByAssociate: 'pos_sales_by_associate',
  tipReport: 'tip_report',
  posWineClubSignups: 'pos_wine_club_signups',
  posOrderSalesReport: 'pos_order_sales_report',
  posOrderItemSalesReport: 'pos_order_item_sales_report',
  salesByOrderEditType: 'sales_by_order_edit_type_report',
  salesByOrderEdit: 'sales_by_order_edit_report',
  posSalesByLocation: 'pos_sales_by_location',
  emailOptinAndSignupSourceReport: 'email_optin_and_signup_source_report',
  inventoriesByLocationSummary: 'inventories_by_location_summary_report',
  posSalesBySku: 'pos_sales_summary_by_sku_report',
  posSalesByCategory: 'pos_sales_summary_by_category_report',
  salesComplianceReport: 'sales_compliance_report',
  masterSalesReport: 'master_sales_report',
}

export const globalFilter = {
  css: 'btn p-2 w-100 btn-tab rotate fs-6 btn-active-color-text-primary justify-content-center border border-gray-400 rounded-0 hide-toggle min-w-100px',
}

export const subscriptionStatus = {
  locked: 'LOCKED',
  active: 'ACTIVE',
  inActive: '',
  suspended: 'SUSPENDED',
}

export const eventNames = {
  invoicePayment: 'invoicePayment',
  retryWineClubOrder: 'wineClubOrderRetry',
  fulfillmentRetry: 'fulfillmentRetry',
  orderCreate: 'orderCreate',
  orderEdit: 'orderEdit',
  orderCancel: 'orderCancel',
  segmentCreate: 'segmentCreate',
  campaignCreate: 'campaignCreate',
  packageSlipCreate: 'packageSlipCreate',
  fulfilledLocationChange: 'fulfilledLocationChange',
  externalOrderCreate: 'externalOrderCreate',
}

export const targetValueType = {
  quantity: 'QUANTITY',
  orderValue: 'ORDER_VALUE',
}

export const premiumFeaturesType = {
  POS: 'POS',
  QuickBooks: 'QUICKBOOKS',
}

export const paymentProviderTypes = {
  stripe: 'stripe',
  elavon: 'elavon',
}

export const addPaymentStatus = {
  succeeded: 'SUCCEEDED',
  declined: 'DECLINED',
  cancelled: 'CANCELLED',
  error: 'ERROR',
  requireAction: 'REQUIRES_ACTION',
}

export const wineClubSubscriptionStatus = {
  cancelled: 'cancelled',
  pause: 'pause',
  switched: 'switched',
}

export const memberShipStatus = {
  active: 'ACTIVE',
  pause: 'PAUSE',
}

export const orderTypes = {
  POS: 'POS',
  ADMIN_PORTAL: 'ADMIN_PORTAL',
  WINE_CLUB: 'WINE_CLUB',
}

export const shippingComplianceProviderTypes = {
  sovos: 'sovos',
  avalara: 'avalara',
  manual: 'manual',
}

export const apiResponseStatus = {
  succeeded: 'succeeded',
  failed: 'failed',
  error: 'error',
}
